import "typeface-lato"
import "./src/css/main.css"
import "./src/css/common/global.css"
import "./src/css/components/button.css"
import "./src/css/components/link-highlight.css"
import "./src/css/components/mapbox.css"
import "./src/css/components/rich-text.css"

import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import React from "react"

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <>
        {element}
        <ReactQueryDevtools />
      </>
    </QueryClientProvider>
  )
}
